import React, { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Button } from "../components/ui/button"
import { Label } from "../components/ui/label"
import { Input } from "../components/ui/input"

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"
import { Switch } from "../components/ui/switch"
import { get } from 'http';


const Leaderboard = () => {
  const { session, user } = useAuth();

  const [scoresThisWeek, setScoresThisWeek] = useState([]);
  const [scoresAllTime, setScoresAllTime] = useState([]);
  const [podScoresThisWeek, setPodScoresThisWeek] = useState([]);
  const [podScoresAllTime, setPodScoresAllTime] = useState([]);
  const [allTimeSwitch, setAllTimeSwitch] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate() + 1).padStart(2, '0');
  const tmrwDate =`${year}-${month}-${day}`;

  const getLastSunday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    
    // If today is Sunday (0), use today's date
    // Otherwise subtract the day number to get to previous Sunday
    const daysToSubtract = dayOfWeek === 0 ? 0 : dayOfWeek;
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - daysToSubtract);
   
    // Format as YYYY-MM-DD
    const year = lastSunday.getFullYear();
    const month = String(lastSunday.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(lastSunday.getDate()).padStart(2, '0');
   
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (session) {
      axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/scores?startDate=' + getLastSunday() + '&endDate=' + tmrwDate, {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setScoresThisWeek(response.data.filter((item: any) => item.total_score !== null))
      }).catch((error) => {
        console.log(error);
      });

      axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/scores', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setScoresAllTime(response.data.filter((item: any) => item.total_score !== null))
      }).catch((error) => {
        console.log(error);
      });

      axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/scoresByPod?startDate=' + getLastSunday() + '&endDate=' + tmrwDate, {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setPodScoresThisWeek(response.data.filter((item: any) => item.total_score !== null))
      }).catch((error) => {
        console.log(error);
      });      

      axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/scoresByPod', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setPodScoresAllTime(response.data.filter((item: any) => item.total_score !== null))
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [session]);



  return (
    (session && user && scoresThisWeek && scoresAllTime) && (
      <>
      <div className="flex flex-row justify-between w-2/3 mx-auto"> 
        <p className="text-2xl font-bold"> Weekly Leaderboard </p>
        <div>
          <Switch 
            checked={allTimeSwitch}
            onCheckedChange={(e) => setAllTimeSwitch(!allTimeSwitch)} 
          />
          <Label className='self-center ml-2'>All Time</Label>
        </div>

      </div>

      <Tabs defaultValue="users" className="w-2/3 mx-auto mt-6">
        <TabsList className="grid w-md grid-cols-2">
          <TabsTrigger value="users">Individuals</TabsTrigger>
          <TabsTrigger value="pods">Pods</TabsTrigger>
        </TabsList>
        <TabsContent value="users">
          <Table className="">
            <TableHeader>
              <TableRow>
                <TableHead >Email</TableHead>
                <TableHead>Score</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {allTimeSwitch ? 
              
              scoresAllTime.map((item: any) => (
                <TableRow key={item.user_id}>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.total_score}</TableCell>
                </TableRow>
              )) : 
            
              scoresThisWeek.map((item: any) => (
                <TableRow key={item.user_id}>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.total_score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabsContent>
        <TabsContent value="pods">
          <Table className="mt-10">
            <TableHeader>
              <TableRow>
                <TableHead >Pod Name</TableHead>
                <TableHead>Average Score</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {allTimeSwitch ? 

              podScoresAllTime.map((item: any) => (
                <TableRow key={item.pod_id}>
                  <TableCell>{item.pod_name}</TableCell>
                  <TableCell>{Number(item.total_score).toFixed(2)}</TableCell>
                </TableRow>
              )) : 

              podScoresThisWeek.map((item: any) => (
                <TableRow key={item.pod_id}>
                  <TableCell>{item.pod_name}</TableCell>
                  <TableCell>{Number(item.total_score).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabsContent>
      </Tabs>
      </>
    )
  );
};

export default Leaderboard;