import React, { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";

interface UserStats {
  email: string;
  total_challenges: number;
  total_responses: number;
  response_rate: number;
}

interface PodStats {
  pod_name: string;
  total_challenges: number;
  total_responses: number;
  response_rate: number;
}

const Analytics = () => {
  const { session, user } = useAuth();
  const [userStats, setUserStats] = useState<UserStats[]>([]);
  const [podStats, setPodStats] = useState<PodStats[]>([]);

  useEffect(() => {
    if (session && user) {
      fetchAnalytics();
    }
  }, [session, user]);

  const fetchAnalytics = async () => {
    try {
      // Fetch user statistics
      const userResponse = await axios.get(process.env.REACT_APP_BACKEND_URL! + '/analytics/response-rates/users', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      });
      setUserStats(userResponse.data);

      // Fetch pod statistics
      const podResponse = await axios.get(process.env.REACT_APP_BACKEND_URL! + '/analytics/response-rates/pods', {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      });
      setPodStats(podResponse.data);

    } catch (error) {
      console.error('Error fetching analytics:', error);
    }
  };

  if (user?.role !== 'TEAM_ADMIN') {
    return (
      <div>
        <h1>Unauthorized</h1>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-3xl font-bold mb-6">Analytics Dashboard</h1>

      <h2 className="text-2xl font-semibold mb-4">Response Rates (Last 14d)</h2>
      
      <Tabs defaultValue="users" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="pods">Pods</TabsTrigger>
        </TabsList>

        <TabsContent value="users">
          <Card>
            <CardHeader>
              <CardTitle>User Response Rates</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Email</TableHead>
                    <TableHead>Total Challenges</TableHead>
                    <TableHead>Total Responses</TableHead>
                    <TableHead>Response Rate</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {userStats.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.total_challenges}</TableCell>
                      <TableCell>{user.total_responses}</TableCell>
                      <TableCell>{user.response_rate}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="pods">
          <Card>
            <CardHeader>
              <CardTitle>Pod Response Rates</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Pod Name</TableHead>
                    <TableHead>Total Challenges</TableHead>
                    <TableHead>Total Responses</TableHead>
                    <TableHead>Response Rate</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {podStats.map((pod, index) => (
                    <TableRow key={index}>
                      <TableCell>{pod.pod_name}</TableCell>
                      <TableCell>{pod.total_challenges}</TableCell>
                      <TableCell>{pod.total_responses}</TableCell>
                      <TableCell>{pod.response_rate}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Analytics;
