import React, { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

const Responses = () => {
  const { session, user } = useAuth();
  const [responses, setResponses] = useState([]);
  const [nonresponders, setNonresponders] = useState([]);
  const [challenge, setChallenge] = useState<any>(null);

  const params = useParams();
  const challengeId = params.challenge_id;

  useEffect(() => {
    if (session && challengeId) {
      // Fetch all responses for this challenge
      axios.get(process.env.REACT_APP_BACKEND_URL! + `/challenges/responses/${challengeId}/all`, {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setResponses(response.data);
      }).catch((error) => {
        console.log(error);
      });

      // Fetch all nonresponders for this challenge
      axios.get(process.env.REACT_APP_BACKEND_URL! + `/challenges/responses/${challengeId}/nonresponders`, {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      }).then((response) => {
        setNonresponders(response.data);
      }).catch((error) => {
        console.log(error);
      });

    }
  }, [session, challengeId]);

  return (
    (session && user && responses) && (
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Responses</h2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Response</TableHead>
              <TableHead>Score</TableHead>
              <TableHead>Critique</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {responses.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.response_text || '-'}</TableCell>
                <TableCell>{item.grade_score || '-'}</TableCell>
                <TableCell>{item.grade_critique || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <h2 className="text-2xl font-bold mt-10">Nonresponders</h2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {nonresponders.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell>{item.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  );
};

export default Responses;